<template>
	<MhRouterView class="NotFoundView view" :isContentReady="isContentReady">

		<Ts404Message class="view__inner"></Ts404Message>

	</MhRouterView>
</template>

<script>
	// @ is an alias to /src
	import EventBus from '@/helper/EventBus.js'

	import MhRouterView from './../components/MhRouterView/v2/MhRouterView.vue'
	import Ts404Message from '@/organisms/Ts404Message.vue'

	export default {
		name: 'NotFoundView',
		components: {
			MhRouterView,
			Ts404Message,
		},
		props: {},
		data() {
			return {
				isContentReady : false,
			}
		},
		computed: {
			currentUrl(){
				return window.location
			}
		},
		methods: {},
		mounted(){
			this.isContentReady = true
		}
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.NotFoundView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
